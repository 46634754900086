<template>
    <section class="attachments-container">
        <!-- <el-upload
            class="upload-demo"
            drag
            action="https://jsonplaceholder.typicode.com/posts/"
            multiple
        >
            <div class="el-upload__text">
            Drag & drop or <em style="color: #FAA100;">upload from your device</em>
            </div>
            <template #tip>
            <div class="el-upload__tip">
            </div>
            </template>
        </el-upload>
        <div class="uploaded-container">
           <p>Uploaded</p>
           <el-scrollbar class="uploaded-list" v-if="hasUploads">
               <UploadedItemComponent/>
               <UploadedItemComponent/>
               <UploadedItemComponent/>
               <UploadedItemComponent/>
               <UploadedItemComponent/>
               <UploadedItemComponent/>
               <UploadedItemComponent/>
               <UploadedItemComponent/>
               <UploadedItemComponent/>
           </el-scrollbar>
           <div class="no-content" v-else>
               <NoContentComponent/>
           </div>
        </div> -->

        <ProjectUploadForm
          :record="form"
          :allowMultiple="true"
          :attachmentSources="attachmentSources"
          :useCustomUploadIcon="true"
          @on-progress-upload="onProgressUpload"
          />
    </section>
</template>
<script>
// import NoContentComponent from '@/core/components/messages/NoContentComponent.vue';
// import UploadedItemComponent from '@/modules/project-post/components/UploadedItemComponent.vue';

import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import ProjectUploadForm from '@/core/components/common/forms/upload/ProjectUpload.vue';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    ProjectUploadForm
  },

  emits: ['on-update-value', 'on-progress-upload'],

  props: ['projectAttachments', 'listType'],

  data() {
    return {
      form: {},
      hasUpload: false,
      attachmentSources: [],
    };
  },

  watch: {
    form: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value.attachments?.length) {
          this.onUpdateValue(value.attachments);
        }
      }
    }
  },

  created() {
    this.initialize();
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user']),
  },

  methods: {
    initialize() {
      if (this.projectAttachments.length) {
        this.attachmentSources = this.projectAttachments;
      }
    },

    onProgressUpload() {
      this.$emit('on-progress-upload');
    },

    onUpdateValue(attachments) {
      this.$emit('on-update-value', attachments);
    }
  }
});
</script>
