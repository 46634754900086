import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import DownloadAttachment from '@/core/components/common/attachments/DownloadAttachment.vue';
import AttachmentsComponent from '@/modules/project-post/components/AttachmentsComponent.vue';
import { PROJECTS_STORE } from '@/store/modules/projects';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    components: {
        AttachmentsComponent,
        DownloadAttachment
    },
    data() {
        return {
            apiUrl: process.env.VUE_APP_API_URL,
            isButtonDisabled: false,
            formData: {
                attachments: []
            }
        };
    },
    computed: {
        ...mapGetters(['isTradesperson']),
        ...mapGetters(PROJECTS_STORE, [
            'currentSelectedProject',
        ]),
        ...mapGetters(USERS_STORE, ['user', 'wsRole', 'isWsAdmin']),
        canCreate() {
            const { wsRole, isWsAdmin } = this;
            let show = true;
            if (wsRole && !isWsAdmin) {
                show = !show;
            }
            return show;
        },
    },
    created() {
        this.initialize();
    },
    methods: {
        ...mapActions(PROJECTS_STORE, ['getProjectByRefId', 'updateProject', 'setCurrentSelectedProject']),
        initialize() {
            if (!this.currentSelectedProject) {
                const { params } = this.$route;
                const { projectRefId } = params;
                this.navigateToProjectOverview(projectRefId);
            }
        },
        navigateToProjectOverview(projectRefId) {
            this.$router.push(`/projects/${projectRefId}/project-details/overview`);
        },
        onSave() {
            const { formData, currentSelectedProject } = this;
            this.isButtonDisabled = true;
            this.updateProject({ projectId: currentSelectedProject.id, formData })
                .then(async (response) => {
                if (response) {
                    this.$notify.success({
                        title: 'Project Update Successful',
                        message: 'Updating Project Details successful.',
                        duration: 4000,
                    });
                    await this.getProjectByRefId(currentSelectedProject.refId)
                        .then((projectResponse) => {
                        if (projectResponse) {
                            this.setCurrentSelectedProject(projectResponse);
                        }
                    });
                    this.$emit('close');
                }
            })
                .catch((e) => {
                this.$notify.error({
                    title: 'Project Update Error',
                    message: e?.data?.message ?? 'Updating Project Details error occurred.',
                });
            })
                .finally(() => {
                this.isButtonDisabled = false;
            });
        },
        onUpdateAttachments(attachments) {
            this.formData.attachments = attachments;
            this.isButtonDisabled = false;
        },
        onProgressUpload() {
            this.isButtonDisabled = true;
        },
    }
});
